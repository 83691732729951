<template>
  <el-dialog
    :visible="showDialog"
    @open="create"
    width="50%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <v-layout row wrap mb-6>
      <v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
        <el-alert
          v-if="statusDocument && statusDocument.sent"
          :title="statusDocument.description"
          type="success"
          center
          :closable="false"
          show-icon
        ></el-alert>
        <el-alert
          v-if="statusDocument && !statusDocument.sent"
          title="El Comprobante aún no ha sido enviado a Sunat"
          type="warning"
          center
          :closable="false"
          show-icon
        ></el-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
        <h3>{{ form.document_type }}</h3>
      </v-flex>
      <v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
        <h4>{{ form.number }}</h4>
      </v-flex>
      <v-flex lg12 xl12 xs12 sm12 md12 d-flex justify-center>
        <h4>TOTAL: {{ form.currency }} {{ form.total }}</h4>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col cols="12" xl="4" lg="4" offset-xl="4" offset-lg="4">
        <el-button type="primary" class="btn-block" block @click="clickPrint()">
          Imprimir comprobante
        </el-button>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="12" sm="12">
        <el-button type="success" class="btn-block" @click="clickDownloadPDF()">
          Descargar PDF
        </el-button>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="12" sm="12">
        <el-button type="primary" class="btn-block" @click="clickDownloadXML()">
          Descargar XML
        </el-button>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="12" sm="12">
        <el-button type="success" class="btn-block" @click="clickDownloadCDR()">
          Descargar CDR
        </el-button>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <el-divider>ENVIAR COMPROBANTE</el-divider>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <el-input v-model="form.email">
          <el-button
            slot="append"
            icon="el-icon-message"
            @click="clickSendEmail"
            :loading="loading"
          >
            <template v-if="loading">
              Enviando...
            </template>
            <template v-else>
              Enviar
            </template>
          </el-button>
        </el-input>
        <small
          class="form-control-feedback"
          v-if="errors.email"
          v-text="errors.email[0]"
        ></small>
      </v-col>
	  <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <el-input v-model="form.customer_telephone">
          <template slot="prepend">+51</template>
          <el-button
            slot="append"
            icon="el-icon-s-promotion"
            @click="clickSendWhatsApp"
          >
            Enviar
            <el-tooltip
              class="item"
              effect="dark"
              content="Es necesario tener aperturado Whatsapp web"
              placement="top-start"
            >
              <i class="fas fa-whatsapp"></i>
            </el-tooltip>
          </el-button>
        </el-input>
      </v-col>
    </v-row>
    <span slot="footer" class="dialog-footer">
      <template v-if="showClose">
        <v-btn color="error" @click="clickClose">Cerrar</v-btn>
      </template>
      <template v-if="isGenerate">
        <v-btn @click="clickFinalize">Ir al listado</v-btn>

        <v-btn color="primary" @click="clickNewDocument"
          >Nuevo Comprobante</v-btn
        >
      </template>
      <template v-if="isFastSale">
        <v-btn color="primary" @click="clickNewSale">Nueva Venta</v-btn>
      </template>
      <template v-if="isSaleReceivable">
        <v-btn color="error" :to="{ name: 'Rent' }">Ir a recepción</v-btn>
      </template>
    </span>
  </el-dialog>
</template>

<script>
import printJS from "print-js";

export default {
  props: [
    "showDialog",
    "recordId",
    "statusDocument",
    "showClose",
    "isContingency",
    "isSaleReceivable",
    "isGenerate",
    "isFastSale",
  ],
  data() {
    return {
      loading: false,
      resource: "documents",
      errors: {},
      form: {},
      company: {},
    };
  },
  created() {},
  methods: {
    getCompany() {
      this.$http.get(`/companies/record`).then((response) => {
        if (response.data !== "") {
          this.company = response.data.data;
        }
      });
    },
    initForm() {
      this.errors = {};
      this.form = {
        email: null,
        customer_telephone: null,
        download_pdf: null,
        external_id: null,
        number: null,
        id: null,
      };
      this.company = {
        soap_type_id: null,
      };
    },
    async create() {
      // await this.getCompany()
      await this.initForm();
      await this.$http
        .get(`/${this.resource}/record/${this.recordId}`)
        .then((response) => {
          this.form = response.data.data;
        });
    },
    clickPrint() {
      let config = this.form.invoice.config;

      if (config.print_mode == "direct") {
        let pc_ip = config.pc_ip;
        let data = JSON.stringify(this.form.invoice);

        let url = `https://${pc_ip}/print-api/print/bill`;

        fetch(url, { method: "POST", body: data })
          .then((dataWrappedByPromise) => dataWrappedByPromise.json())
          .then((data) => {
            if (data.success) {
              this.$message.success(data.message);
            } else {
              this.$message.error(data.message);
            }
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      } else {
        printJS(`${this.form.print}`);
      }
    },
    clickDownloadPDF() {
      window.open(`${this.form.download_pdf}`, "_blank");
    },
    clickDownloadXML() {
      window.open(`${this.form.download_xml}`, "_blank");
    },
    clickDownloadCDR() {
      window.open(`${this.form.download_cdr}`, "_blank");
    },
    clickDownload() {
      window.open(`${this.form.download_pdf}`, "_blank");
    },
    clickSendEmail() {
      this.loading = true;
      this.$http
        .post(`/${this.resource}/email`, {
          email: this.form.email,
          id: this.form.id,
        })
        .then((response) => {
          if (response.data.success) {
            this.$message.success("El correo fue enviado satisfactoriamente");
          } else {
            this.$message.error("Error al enviar el correo");
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            this.$message.error(error.response.data.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    clickSendWhatsApp() {
      if (!this.form.customer_telephone) {
        return this.$message.error("El número es obligatorio");
      }

      window.open(
        `https://wa.me/51${this.form.customer_telephone}?text=${this.form.message_text}`,
        "_blank"
      );
    },
    clickFinalize() {
      this.$router.push({ name: "DocumentIndex" });
    },
    clickNewDocument() {
      this.clickClose();
    },
    clickClose() {
      this.$emit("update:showDialog", false);
      this.initForm();
    },
    clickNewSale() {
      this.initForm();
      this.$router.push({ name: "SaleIndex" });
    },
  },
};
</script>
